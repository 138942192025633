const isBrowser = typeof window !== 'undefined'

const nullStorage = {
  getItem(_, defaultValue = null) {
    return defaultValue
  },
  setItem() {},
}

const createStorage = storage => {
  if (!storage) return nullStorage

  return {
    getItem(key, defaultValue = null) {
      try {
        const item = key ? storage.getItem(key) : null
        return item ? JSON.parse(item) : defaultValue
      } catch (error) {
        console.log(error)
        return defaultValue
      }
    },
    setItem(key, value) {
      try {
        if (key) {
          storage.setItem(key, JSON.stringify(value))
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}

const createInMemoryStorage = () => {
  const storage = new Map()
  return {
    getItem(key, defaultValue = null) {
      return storage.has(key) ? storage.get(key) : defaultValue
    },
    setItem(key, value) {
      storage.set(key, value)
    },
  }
}

//export const LocalStorage = createStorage(isBrowser && window.localStorage)

export const SessionStorage = createStorage(isBrowser && window.sessionStorage)

export const InMemoryStorage = createInMemoryStorage()
