import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useReducer,
} from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import useMatchMedia from '../../hooks/use-media'
import { InMemoryStorage } from '../../utils/storage'

const CarouselViewport = styled.div`
  overflow: hidden;
  width: 100%;

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`

const CarouselContainer = styled.div`
  display: flex;
  user-select: none;
`

const ThumbnailContainer = styled(CarouselContainer)`
  ${mediaQueries.lg} {
    flex-direction: column;
  }
`

export const Thumbnails = forwardRef(({ children, ...rest }, ref) => (
  <CarouselViewport {...rest} ref={ref}>
    <ThumbnailContainer>{children}</ThumbnailContainer>
  </CarouselViewport>
))

export const Slides = forwardRef(({ children, ...rest }, ref) => (
  <CarouselViewport {...rest} ref={ref}>
    <CarouselContainer>{children}</CarouselContainer>
  </CarouselViewport>
))

const useCarouselSelect = (carousel, callback) => {
  useEffect(() => {
    const handleSelect = () => callback(carousel)
    if (carousel) carousel.on('select', handleSelect)
    return () => {
      if (carousel) carousel.off('select', handleSelect)
    }
  }, [carousel, callback])
}

const loadState = storageKey => {
  const index = InMemoryStorage.getItem(storageKey, 0)
  return { selectedIndex: index, startIndex: index }
}

const saveState = (storageKey, value) =>
  InMemoryStorage.setItem(storageKey, value)

const reducer = (state, [type, payload]) => {
  switch (type) {
    case 'select':
      return {
        ...state,
        selectedIndex: payload.index,
      }
    case 'changeMedia':
      return {
        ...state,
        startIndex: state.selectedIndex,
      }
    default:
      throw new Error()
  }
}

const DEFAULT_DESKTOP_OPTIONS = {
  draggable: true,
  speed: 40,
}

const DEFAULT_MOBILE_OPTIONS = {
  draggable: true,
  speed: 20,
}

export const useCarousel = (storageKey, options = {}) => {
  const isMobile = !useMatchMedia(mediaQueries.lg)

  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(false)

  const [state, dispatch] = useReducer(reducer, storageKey, loadState)
  const { startIndex, selectedIndex } = state

  const [mainViewportRef, mainCarousel] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    startIndex: startIndex,
    ...(isMobile
      ? { ...DEFAULT_MOBILE_OPTIONS, ...options.mobile }
      : { ...DEFAULT_DESKTOP_OPTIONS, ...options.desktop }),
  })
  const [thumbViewportRef, thumbCarousel] = useEmblaCarousel({
    align: 'start',
    startIndex: startIndex,
  })

  const handleSelect = useCallback(
    carousel => dispatch(['select', { index: carousel.selectedScrollSnap() }]),
    [],
  )

  const scrollPrev = () => mainCarousel && mainCarousel.scrollPrev()

  const scrollNext = () => mainCarousel && mainCarousel.scrollNext()

  useCarouselSelect(mainCarousel, handleSelect)
  useCarouselSelect(thumbCarousel, handleSelect)

  useEffect(() => {
    if (!mainCarousel) return

    const handleSelect = () => {
      setCanScrollPrev(mainCarousel.canScrollPrev())
      setCanScrollNext(mainCarousel.canScrollNext())
    }

    handleSelect()
    mainCarousel.on('reInit', handleSelect)
    mainCarousel.on('select', handleSelect)

    return () => {
      mainCarousel.off('reInit', handleSelect)
      mainCarousel.off('select', handleSelect)
    }
  }, [mainCarousel])

  useEffect(() => {
    saveState(storageKey, selectedIndex)
  }, [storageKey, selectedIndex])

  useEffect(() => {
    dispatch(['changeMedia', { isMobile }])
  }, [isMobile])

  useEffect(() => {
    if (mainCarousel) mainCarousel.scrollTo(selectedIndex)
    if (thumbCarousel) thumbCarousel.scrollTo(selectedIndex)
  }, [thumbCarousel, mainCarousel, selectedIndex])

  return {
    thumbnailsRef: isMobile ? thumbViewportRef : null,
    slidesRef: mainViewportRef,
    select: index => dispatch(['select', { index }]),
    selectedIndex,
    canScrollPrev,
    canScrollNext,
    scrollPrev,
    scrollNext,
  }
}
