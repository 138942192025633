import { useState, useEffect, useLayoutEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

const createUseMedia = effect => rawQuery => {
  const query = rawQuery.replace(/@media\s*/gi, '')

  const [state, setState] = useState(() =>
    isBrowser ? window.matchMedia(query).matches : false,
  )

  effect(() => {
    let mounted = true
    const mediaQueryList = window.matchMedia(query)

    const handleChange = () => {
      if (mounted) {
        setState(mediaQueryList.matches)
      }
    }

    mediaQueryList.addEventListener('change', handleChange)

    return () => {
      mounted = false
      mediaQueryList.removeEventListener('change', handleChange)
    }
  }, [query])

  return state
}

export const useMedia = createUseMedia(useEffect)
export const useMediaLayout = createUseMedia(useLayoutEffect)

export default useMedia
